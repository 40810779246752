<template>
    <b-card no-body class="p-1">
        <b-overlay :show="state.loading">
            <div class="demo-spacing-0 d-flex justify-content-between m-1" dir="rtl">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    <feather-icon size="16" icon="FilterIcon"/>
                    فیلتر
                </b-button>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <b-modal
                    ref="msg-modal"
                    cancel-variant="outline-secondary"
                    ok-title="ارسال تیکت"
                    cancel-title="بستن تیکت"
                    centered
                    title="پیام جدید"
                    @ok.prevent="sendMessage"
                    @cancel.prevent="closeTicket"
                    :ok-disabled="ticketsContent[0] ? ticketsContent[0].status === 'CLOSED' : false"
                    :cancel-disabled="ticketsContent[0] ? ticketsContent[0].status === 'CLOSED' : false"
                    v-model="modal"
                    size="lg"
            >
                <h3>{{ ticketsContent[0] ? ticketsContent[0].title : '' }}</h3>
                <div v-if="ticketsContent" class="border p-1 chat d-flex flex-column">
                    <div  v-for="ticketContent in ticketsContent"
                          :key="ticketContent.id"
                          :class="{ user : ticketContent.sender_name === 'USER', admin : ticketContent.sender_name !== 'USER' }"
                    >
                        <div class="chat-entry"
                        >
                            {{ ticketContent.body }}
                        </div>
                        <div class="sender">
                            {{ ticketContent.sender.profile ? ticketContent.sender.profile.name : ticketContent.sender.email }}
                        </div>
                    </div>
                </div>
                <b-form v-if="ticketsContent[0] ? ticketsContent[0].status !== 'CLOSED' : true">
                    <b-overlay :show="loading">
                        <b-form-group label="متن پیام">
                            <b-form-textarea placeholder="متن پیام" v-model="body"/>
                        </b-form-group>
                    </b-overlay>
                </b-form>
            </b-modal>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        style="white-space: nowrap; min-height : 235px"
                        @sort-changed="sort($event)"
                        no-local-sort
                >

                    <template #cell(name)="data">
                        {{ data.item.sender.profile ? data.item.sender.profile.name : '-' }}
                    </template>

                    <template #cell(email)="data">
                        {{ data.item.sender.email }}
                    </template>

                    <template #cell(status)="data">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(data.item.status)"
                                class="text-capitalize"
                                :badge="12"
                        >
                            {{ statusLabel(data.item.status) }}
                        </b-badge>
                    </template>

                    <template #cell(title)="data">
                        <p class="ellipsis cursor-pointer mb-0"
                           @click.prevent="showTicket(data.item.id)">
                            {{ data.item.title }}</p>
                    </template>
                    <template #cell(created_at)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.created_at) }}
                        </span>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                    <label for="perpage">تعداد در صفحه</label>
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import {
    BPagination,
    BCard,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    // BFormInput,
    BFormTextarea,
    BModal,
    BOverlay,
    // BDropdown,
    // BDropdownItem,
    BBadge,
    // BAvatar,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';

export default {
    name: 'TicketPage',
    components: {
        SearchAndFilter,
        BPagination,
        BCard,
        BButton,
        BTable,
        BForm,
        BFormGroup,
        // BFormInput,
        BFormTextarea,
        BModal,
        BOverlay,
        // BDropdown,
        // BDropdownItem,
        BBadge,
        // BAvatar,
        vSelect,
    },
    data: () => ({
        test: 0,
        pageLength: 3,
        dir: false,
        searchTerm: '',
        currentPage: 1,
        perPage: 10,
        rows: 10,
        sortBy: '',
        isSortDirDesc: '',
        modal: false,
        loading: false,
        body: '',
        ticketsContent: [],
        userData: [],
        show: [],
        isActive: false,
        perPageOptions: [5, 10, 20, 50, 100],
        items: [],
        columns: [
            {
                label: 'متعلق به',
                key: 'name',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'ایمیل',
                key: 'email',
                sortable: false,
                searchType: 'text'
            },
            {
                label: 'وضعیت',
                key: 'status',
                sortable: false,
                searchType: 'select',
                selectOptions: [
                    {label: 'در حال انتظار', value: 'PENDING'},
                    {label: 'پاسخ داده شده', value: 'ANSWERED'},
                    {label: 'بسته شده', value: 'CLOSED'}
                ]
            },
            {
                label: 'تاریخ ایجاد پیام',
                key: 'created_at',
                sortable: true,
                searchType: 'date'
            },
            {
                label: 'عنوان پیام',
                key: 'title',
                sortable: false,
            },
        ],
    }),
    computed: {
        statusVariant() {
            const a = {
                PENDING: 'info',
                ANSWERED: 'success',
                CLOSED: 'danger'
            }
            return e => a[e]
        },
        statusLabel() {
            const a = {
                PENDING: 'در حال انتظار',
                ANSWERED: 'پاسخ داده شده',
                CLOSED: 'بسته شده'
            }
            return e => a[e]
        },
    },
    methods: {
        async showTicket(e) {
            await this.getTicket(e)
            this.modal = true
        },
        async getTicket(e) {
            const res = await this.$axios.get('/tickets/' + e)
            this.ticketsContent = res.data.data
        },
        async sendMessage() {
            if (!this.loading) {
                this.loading = true
                try {
                    const id = this.ticketsContent[0].id
                    let body = {
                        title: '',
                        body: this.body
                    }
                    await this.$axios.post('/tickets/' + id, body)
                    this.loading = false
                    this.message = ''
                    await this.getTicket(id)
                    await this.getData(this.currentPage, this.perPage)
                    this.$error('پیام ارسال شد', '', 'success')
                } catch (e) {
                    this.loading = false
                }
            }
        },
        async closeTicket() {
            if (!this.loading) {
                this.loading = true
                try {
                    const id = this.ticketsContent[0].id
                    await this.$axios.patch('/tickets/' + id + '/close')
                    this.$error('پیام ارسال شد', '', 'success')
                    await this.getData(this.currentPage, this.perPage)
                    this.modal = false
                } catch (e) {
                    this.loading = false
                }

            }
        },
        sort(e) {

            let sort = this.$toSnakeCase(e.sortBy)
            // let sort = e.sortBy
            let sorting = e.sortDesc ? 'DESC' : 'ASC'

            this.$router.push({
                query: {
                    ...this.$route.query,
                    orderBy: sort,
                    sorting: sorting
                }
            }).catch(() => {
            })

            this.getData(1, this.perPage)

        },
        async getData(page, perPage) {
            this.state.loading = true

            let queryParams = {
                size: perPage,
                page: page,
                ...this.$route.query,
            }

            const res = await this.$axios(
                '/tickets',
                {
                    params: queryParams
                }
            )

            this.state.loading = false
            this.items = res.data.data
            this.currentPage = res.data.meta.current_page
            this.rows = res.data.meta.last_page
        },
    },
    mounted() {
        this.getData(1, this.perPage)
    },
}
</script>
<style lang="scss" scoped>
.chat-entry {
  width: 80%;
  border-radius: 3px 3px 0px 3px;
  background-color: rgba(158, 157, 138, 0.25);
  border: 1px solid rgb(158, 157, 138);
  padding: 5px;
  font-size: 1em;
  position: relative;

}

.user {
  margin-bottom: 10px;
  .chat-entry {
    margin-left: auto;
  }
  > .sender {
    font-size: 0.8em;
    text-align: right;
  }
}

.admin {
  margin-bottom: 10px;
  .chat-entry {
    margin-right: auto;
  }
  > .sender {
    font-size: 0.8em;
    text-align: left;
  }
}
</style>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}

.ellipsis {
  overflow: hidden;
  width: calc(100vw - 1000px);
  text-overflow: ellipsis;
  white-space: nowrap;

  &[show] {
    white-space: normal;
  }

  @media(max-width: 1200px) {
    width: calc(100vw - 470px);
    min-width: 200px;
  }
}
</style>
